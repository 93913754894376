export enum EventType {
  TAP_BET = 'TAP_BET',
  UNTAP_BET = 'UNTAP_BET',
  MATCH_LIST_VIEW = 'MATCH_LIST_VIEW',
  MATCH_LIST_SELECT = 'MATCH_LIST_SELECT',
  MATCH_LIST_BACK = 'MATCH_LIST_BACK',
  MATCH_VIEW = 'MATCH_VIEW',
  MATCH_VIEW_BOTTOM = 'MATCH_VIEW_BOTTOM',
  MATCH_MARKET_SHOW_MORE = 'MATCH_MARKET_SHOW_MORE',
  MATCH_BACK = 'MATCH_BACK',
  CONFIRM_BET = 'CONFIRM_BET',
  CLICK_CLOSE = 'CLICK_CLOSE',
  CLICK_CART = 'CLICK_CART',
  TOGGLE_COMBO = 'TOGGLE_COMBO',
  RATE_POSITIVE = 'RATE_POSITIVE',
  RATE_NEGATIVE = 'RATE_NEGATIVE',
  FEEDBACK_TEXT = 'FEEDBACK_TEXT',
}
